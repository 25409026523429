export const menu_box = {
    f2000: {
        title: 'Foot 2000',
        exist: true,
        items: [
            {
                icon: "../static/pin.png",
                name: "Centre de ressources",
                exist: true,
                url_site_web: 'https://foot2000pp.fff.fr/F2000Q/f2000_start',
                cookies: [
                    {
                        'nom': 'F2K_PARAMS',
                        'path': '/F2000Q',
                        'domain': 'fff.fr',
                        'value': 'XXX'
                    },
                    {

                        'nom': 'F2000_SESSION',
                        'path': '/F2000Q',
                        'domain': 'fff.fr',
                        'value': 'XXX'
                    },
                    {
                        'nom': 'F2000_SESSION',
                        'path': '/',
                        'domain': 'fff.fr',
                        'value': 'XXX'
                    }

                ]
            },
            {
                icon: "../static/LogoPictosEtFonds/BlocFOOT2000-VignettePersonne.png",
                name: "Personne",
                exist: true
            },
            {
                icon: "../static/LogoPictosEtFonds/BlocFOOT2000-VignetteClub.png",
                name: "Clubs",
                exist: true
            },
            {
                icon: "../static/LogoPictosEtFonds/BlocFOOT2000-VignetteCompetitions.png",
                name: "Compétitions",
                exist: true
            },
            {
                icon: "../static/LogoPictosEtFonds/BlocFOOT2000-VignetteAdministration.png",
                name: "Administration",
                exist: true
            },
            {
                icon: "../static/LogoPictosEtFonds/BlocFOOT2000-VignettePerso.png",
                name: "Perso",
                exist: false
            }
        ]
    },
    serviceAdmin: {
        title: 'Services administratifs',
        exist: true,
        items: [
            {
                icon: "../static/LogoPictosEtFonds/BlocServicesAdministratifs-VignetteCompetitions.png",
                name: "Compétitions",
                exist: true
            },
            {
                icon: "../static/LogoPictosEtFonds/BlocServicesAdministratifs-VignetteDesignationdesOfficiels.png",
                name: "Désignations des Officiels",
                exist: true
            },
            {
                icon: "../static/LogoPictosEtFonds/BlocServicesAdministratifs-VignetteDistinctionsdesBenevoles.png",
                name: "Distinctions des Bénévoles",
                exist: true
            },
            {
                icon: "../static/LogoPictosEtFonds/BlocServicesAdministratifs-VignetteFAFA.png",
                name: "FAFA",
                exist: true
            },
            {
                icon: "../static/LogoPictosEtFonds/BlocServicesAdministratifs-VignetteFootballAnimationEtLoisir.png",
                name: "Football Animation & Loisir",
                exist: true
            },
            {
                icon: "../static/LogoPictosEtFonds/BlocServicesAdministratifs-VignetteSuiviDuProjetClub.png",
                name: "Suivi du Projet Club",
                exist: true
            }
        ]
    },
    reseauBleu: {
        title: 'Réseau bleu',
        exist: true,
        items: [
            {
                icon: "../static/LogoPictosEtFonds/BlocReseauBleu-Referentieldocumentaire.png",
                name: "Référentiel documentaire",
                url: "https://rb.fff.fr/jcms/hbe_3004286/referentiel-documentaire",
                exist: true
            },
            {
                icon: "../static/LogoPictosEtFonds/BlocReseauBleu-VignetteMesCommunautes.png",
                name: "Mes communautés",
                url: "http://rb.fff.fr/jcms/hbe_3004282/communautes",
                exist: true
            },
            {
                icon: "../static/LogoPictosEtFonds/BlocReseauBleu-VignetteAnnuaire.png",
                name: "Annuaire FFF",
                url: "http://rb.fff.fr/jcms/hbe_3004281/annuaire",
                exist: true
            }
        ]
    },
    universFFF: {
        title: 'Univers FFF',
        exist: true,
        items: [
            {
                icon: "../static/logo_coq.png",
                name: "Site FFF",
                exist: true
            },
            {
                icon: "../static/logo_ligue.png",
                name: "Site Ligue",
                exist: true
            },
            {
                icon: "../static/logo_district.png",
                name: "Site District",
                exist: true
            }
        ]
    },
    habilitations: {
        title: 'Gestion des habilitations',
        exist: true,
        items: [
            {
                icon: "../static/cupChampion.png",
                name: "Compétitions",
                exist: true
            },
            {
                icon: "../static/flute.png",
                name: "Désignations des Officiels",
                exist: true
            },
            {
                icon: "../static/circlet.png",
                name: "Distinctions des Bénévoles",
                exist: true
            },
            {
                icon: "../static/mortier.png",
                name: "FAFA",
                exist: true
            },
            {
                icon: "../static/footballPlayer.png",
                name: "Football Animation & Loisir",
                exist: true
            },
            {
                icon: "../static/startUp.png",
                name: "Suivi du Projet Club",
                exist: true
            }
        ]
    }
}


